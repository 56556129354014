@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,400;0,700;1,400;1,700&family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

* {
  font-family: 'Chivo', sans-serif;
}

body {
  position: relative;
  font-size: 13px;
}

body::before {
  content: '';
  background: url('./assets/background.svg') 50% repeat;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.categories__menu {
  font-size: 14px;
}

[class*='hero'].container {
  text-align: center;
}

.navbar-container {
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  overflow: visible;
}

.navbar-menu a {
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 55px;
  display: inline-block;
}

.navbar-menu a:hover {
  opacity: 0.7;
}

.book-demo {
  border: 2px solid #375aaa;
  border-radius: 10px;
  margin-left: 20px;
  color: #375aaa;
}

.book-demo:hover {
  border: 2px solid #375aaa;
  background-color: #375aaa;
  color: #fff;
}

.navbar-menu a.book-demo:hover {
  opacity: 1;
}

.product-list-price p {
  font-size: 17px;
  color: #888;
  font-weight: 400;
  margin-top: 10px;
}

.product-name a {
  font-size: 17px;
  font-weight: 600;
  color: #555;
}

.product-description {
  color: #212529;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
}

.title {
  font-size: 33px;
  text-align: center;
  padding: 75px 0 25px;
}

.mobile-menu-list {
  margin-top: 88px;
}

.proposal-builder input,
.proposal-builder .react-date-picker > div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-style: dotted;
  outline: none;
}

.proposal-builder input,
.proposal-builder input + div {
  text-align: center;
}

.proposal-builder input:focus {
  box-shadow: none;
  border-bottom-color: #375aaa;
}

.proposal-builder button.main-button {
  border-radius: 5px;
}

.proposal-builder button.main-button:focus {
  outline: none;
}

.dropdown-list {
  border-radius: 5px;
}

.dropdown-trigger button {
  border-radius: 5px;
}

.dropdown-trigger button:focus {
  outline: none;
}

.split-view-switcher li {
  font-family: 'Kanit', sans-serif;
}

.split-view-switcher li:last-child {
  border-radius: 0 5px 5px 0;
}

.split-view-switcher li:first-child {
  border-radius: 5px 0 0 5px;
}

@media only screen and (min-width: 1500px) {
  .container {
    width: 1484px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  [class*='hero_']:not(.container) {
    height: 400px;
  }

  [class*='hero'].container {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 320px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    cursor: pointer;
  }

  .mobile-menu,
  .mobile-menu-list {
    display: block;
  }

  .mobile-menu-list {
    position: fixed;
    z-index: 1001;
    background: #fff;
    width: 100%;
    padding: 20px 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .mobile-menu-list a {
    display: block;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    padding: 20px 0;
  }

  .mobile-menu-list a:hover {
    opacity: 0.8;
  }

  .mobile-menu-list a.book-demo {
    margin-left: 0;
    border: none;
  }

  .mobile-menu-list a.book-demo:hover {
    border: none;
    background-color: transparent;
    color: #375aaa;
  }
}

@media only screen and (min-width: 992px) {
  .desktop-menu {
    display: block;
  }

  .mobile-menu,
  .mobile-menu-list {
    display: none;
  }
}
